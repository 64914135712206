@layer components {
  .base {
    align-items: var(--button-placement);
    color: var(--button-text-color-base);
    display: var(--button-display);
    flex-direction: var(--button-direction);
    font-size: var(--button-font-size);
    font-weight: var(--button-font-weight);
    gap: var(--button-gap);
    height: var(--button-height);
    justify-content: var(--button-placement);
    line-height: 1;
    padding: var(--button-padding);
    text-align: center;
    text-decoration: none;
    transition: all 100ms cubic-bezier(0, 0, 0.2, 1);
    transition-property: color, background-color, border-color;
    white-space: var(--button-white-space);
    width: var(--button-width);
    margin: var(--button-margin);
    min-width: var(--button-min-width);
    border-radius: var(--button-radius-top) var(--button-radius-bottom);
    -webkit-tap-highlight-color: transparent;

    @media (--supports-hover) {
      &:hover {
        color: var(--button-text-color-hover);
      }
    }

    @media (prefers-reduced-motion) {
      transition: none;
    }

    &[disabled],
    &[disabled]:hover {
      cursor: not-allowed;
    }
  }

  .outlined {
    background-color: var(--button-outlined-background-color);
    color: var(--button-outlined-base-color);
    border: 2px solid var(--button-outlined-border-color);
    font-weight: var(--button-outlined-font-weight);

    @media (--supports-hover) {
      &:hover {
        color: var(--button-outlined-color-hover);
        background-color: var(--button-outlined-background-color-hover);
        border: var(--border-radius-1x) solid
          var(--button-outlined-border-hover-color);
      }
      &[disabled]:hover {
        color: var(--color-gray-300);
        border-color: var(--color-gray-300);
      }

      &:active {
        color: var(
          --button-outlined-color-active,
          var(--button-outlined-color-hover)
        );
        background-color: var(
          --button-outlined-background-color-active,
          var(--button-outlined-background-color-hover)
        );
      }
    }

    &[disabled] {
      color: var(--color-gray-300);
      border-color: var(--color-gray-300);
    }
  }

  .inverted {
    color: var(--color-white);
    border: 1px solid var(--color-white);
    font-weight: var(--font-weight-semibold);

    @media (--supports-hover) {
      &:hover {
        color: var(--color-white);
        border-color: var(--color-white);
      }
      &[disabled]:hover {
        color: var(--color-gray-300);
        border-color: var(--color-gray-300);
      }
    }

    &[disabled] {
      color: var(--color-gray-300);
      border-color: var(--color-gray-300);
    }
  }

  .pill {
    background-color: var(--color-white);
    border: var(--space-0-5x) solid var(--button-pill-border-color);
    color: var(--button-pill-color-base);
    font-weight: var(--font-weight-semibold);

    @media (--supports-hover) {
      &:hover {
        color: var(--button-pill-color-hover);
        background-color: var(--button-pill-background-hover);
      }
    }
  }

  .filter {
    border: var(--button-filter-border);
    color: var(--button-filter-color);
    border-bottom: var(--button-filter-border-bottom);

    @media (--supports-hover) {
      &:hover {
        color: var(--button-filter-color-hover);
        background: var(
          --search-results-header-filter-button-background-color-hover
        );
      }

      &:active {
        color: var(--button-filter-color-hover);
        background: var(
          --search-results-header-filter-button-background-color-active,
          var(--search-results-header-filter-button-background-color-hover)
        );
      }
    }
  }

  .agTertiaryRedesign {
    background-color: var(--color-gray-100);
    color: var(--color-black);

    @media (--supports-hover) {
      &:hover {
        background-color: var(--color-gray-200);
        color: var(--color-black);
      }

      &:active {
        background-color: var(--color-gray-300);
        color: var(--color-black);
      }
    }
  }

  .agTertiaryRedesign.rounded {
    border-radius: var(--border-radius-6x);
  }

  .rentSrpSecondary {
    border-radius: var(--border-radius-full);
    background-color: var(--color-indigo-200);
    border-color: var(--color-indigo-200);
    color: var(--color-black);

    @media (--supports-hover) {
      &:hover {
        background-color: var(--color-indigo-300);
        border-color: var(--color-indigo-300);
        color: var(--color-black);
      }
    }
  }

  .rentSrpTertiary {
    background-color: var(--button-tertiary-background-color);
    color: var(--button-tertiary-color);
    border-radius: var(--border-radius-full);

    @media (--supports-hover) {
      &:hover {
        background-color: var(--button-tertiary-background-color-hover);
        color: var(--button-tertiary-color-hover);
      }

      &:active {
        background-color: var(--button-tertiary-background-color-active);
        color: var(--button-tertiary-color-active);
      }
    }
  }

  .anchor {
    display: var(--anchor-display);
    align-items: var(--anchor-placement);
    justify-content: var(--anchor-placement);
    flex-direction: var(--anchor-direction);
    gap: var(--anchor-gap);
    color: var(--anchor-text-color-default);
    font-size: var(--anchor-font-size);
    font-weight: var(--anchor-weight);
    text-decoration: var(--anchor-text-decoration-default);

    @media (--supports-hover) {
      &:hover {
        color: var(--anchor-text-color-hover);
        text-decoration: var(--anchor-text-decoration-hover);
      }
    }

    &:active {
      color: var(--anchor-text-color-hover);
      text-decoration: var(--anchor-text-decoration-hover);
    }
  }

  .basic {
    color: var(--color-gray-500);
    background-color: var(--color-white);
    font-weight: var(--font-weight-medium);
    border: 2px solid var(--color-gray-200);

    @media (--supports-hover) {
      &:hover {
        background-color: var(--color-gray-100);
      }
    }
  }

  .primary {
    background-color: var(--button-background-color-base);
    color: var(--color-white);
    font-weight: var(--font-weight-semibold);

    @media (--supports-hover) {
      &:hover {
        background-color: var(--button-background-color-hover);
        color: var(--color-white);
      }
      &[disabled]:hover {
        background-color: var(--button-primary-disabled-background-color);
        color: var(--button-primary-disabled-color);
      }

      &:active {
        background-color: var(--button-background-color-active);
        color: var(--color-white);
      }
    }

    &:active {
      background-color: var(--button-background-color-active);
      color: var(--color-white);
    }

    &[disabled] {
      background-color: var(--button-primary-disabled-background-color);
      color: var(--button-primary-disabled-color);
    }
  }

  .sizeSm {
    height: var(--button-height-sm);
    padding: 0 var(--space-3x);
  }

  .sizeMd {
    height: var(--button-height-md);
    padding: 0 var(--space-4x);
    font-size: var(--font-size-body);
  }

  .sizeLg {
    height: var(--button-height-lg);
    padding: 0 var(--space-6x);
    font-size: var(--font-size-lg);
  }

  .rounded {
    border-radius: var(--button-rounded-border-radius);
  }

  .circle {
    border-radius: var(--border-radius-full);
  }

  .fluid {
    width: 100%;
  }
}
